<template>
  <Modal @on-close="emit('onClose')">
    <Command v-bind="{ ...props, ...emitAsProps, ...attrs }" />
  </Modal>
</template>

<script setup lang="ts">
import type { Props } from "~/components/ui/command/Command.vue";
import type { Emits } from "~/components/modal/Modal.vue";
import { useEmitAsProps } from "radix-vue";

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const emitAsProps = useEmitAsProps(emit);

const attrs = useAttrs();
</script>

<style scoped lang="scss"></style>

<template>
  <SuperCombobox
    v-model:search-term="searchTerm"
    :model-value="[]"
    :options="optionsAfterSearch"
    :placeholder="placeholder"
    style="width: 512px; margin-top: 64px"
    @on-select="
      (item) => {
        item.action();
      }
    "
  >
    <template #option="{ item, isActive }">
      <CommandItem :item="item" :is-active="isActive" />
    </template>
  </SuperCombobox>
</template>

<script setup lang="ts">
import type { LucideIconName } from "~/components/lucide-icon/types";
import Fuse from "fuse.js";

export type ContextMenuOption = { label: string; icon?: LucideIconName; emoji?: string; action: () => void };

export type Props = { options: ContextMenuOption[]; placeholder?: string };

const props = withDefaults(defineProps<Props>(), { placeholder: "Sélectionner une action" });

const searchTerm = ref("");

const searchFilter = function (contextMenuOptions: ContextMenuOption[], searchTerm: string) {
  if (!searchTerm) return contextMenuOptions;

  const fuse = new Fuse(contextMenuOptions, {
    keys: ["label"],
    threshold: 0.5,
  });

  return fuse.search(searchTerm).map((result) => result.item);
};

const optionsAfterSearch = computed<ContextMenuOption[]>(() => searchFilter(props.options, searchTerm.value));
</script>

<style scoped></style>
